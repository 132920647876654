import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HotkeyService {
  lastEvent: KeyboardEvent;
  debugMode = false;

  constructor() { }

  handleHotkeyEvent(event: KeyboardEvent) {
    const tagName = event.currentTarget['activeElement']?.tagName;
    const keys = [];
    if (event.altKey) keys.push('ALT');
    if (event.ctrlKey) keys.push('CONTROL');
    if (event.metaKey) keys.push('META');
    if (event.shiftKey) keys.push('SHIFT');

    // if (tagName !== 'INPUT') {
    //   console.info(`${tagName}  ${keys.join('+')} ${event.key} pressed.`);
    // }

    if (this.lastEvent && this.lastEvent.shiftKey && this.lastEvent.ctrlKey && !this.lastEvent.altKey && this.lastEvent.key === 'S' && event.shiftKey && event.ctrlKey && !event.altKey && event.key === 'D') {
      this.debugMode = !this.debugMode;
      console.info(`Debug Mode = ${this.debugMode ? 'ON' : 'OFF'}`);
    }

    

    this.lastEvent = event;
  }
}
