import { Component, OnInit, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { AuthService } from '../../_services/auth.service';
import { MainService } from '../../_services/main.service';
import { WholesalerModel } from '../../_models/common/wholesaler.model';

@Component({
  selector: 'app-choose-version',
  templateUrl: './choose-version.component.html',
  styleUrls: ['./choose-version.component.scss']
})
export class ChooseVersionComponent implements OnInit {
  public wholesalers: WholesalerModel[];
  public withCookieDeleter: boolean;
  public mainService: MainService;
  public authService: AuthService;
  public onClose: Subject<WholesalerModel>;
  filterString: string;
  focusInput: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private bsModalRef: BsModalRef
  ) { }

  ngOnInit() {
    this.onClose = new Subject();
    setTimeout(() => {
      this.focusInput.emit(true);
    }, 0);
  }

  chooseFirst(wholesalers: WholesalerModel[]) {
    if (wholesalers.length == 1) this.choose(wholesalers[0]);
  }

  public choose(wholesaler: WholesalerModel) {
    if (wholesaler) { this.onClose.next(wholesaler); }
    this.bsModalRef.hide();
  }

  public deleteCookiesWithConfirm(wholesaler: WholesalerModel) {
    this.mainService.confirmBox('Weet u zeker dat u alle cookies voor ' + wholesaler.ShortName + ' wilt wissen?')
      .subscribe(result => {
        if (result) { this.authService.removeAllCookies(wholesaler.ID); }
      });
  }

}
